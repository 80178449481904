import { Heart } from '@/assets/images/icons';
import styles from '@/features/devices/tv/components/jobs/jobContainerIcon.module.scss';
import { ExposureStatus } from '@/types/games/game2/types';

interface JobContainerIconProps {
    icon: string;
    exposureStatus?: keyof typeof ExposureStatus;
}

const JobContainerIcon: React.FC<JobContainerIconProps> = ({
    icon = Heart,
    exposureStatus,
}) => {
    const handleContainerStyle = (): string => {
        if (exposureStatus === ExposureStatus.EXPOSED)
            return styles.exposedContainer;
        if (exposureStatus === ExposureStatus.NOT_EXPOSED)
            return styles.unexposedContainer;
        return styles.notSelectedContainer;
    };

    return (
        <div className={handleContainerStyle()}>
            <img src={icon} alt='Icône du métier' />
        </div>
    );
};

export default JobContainerIcon;
