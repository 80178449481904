import data from '@/data/gameOneData.json';
import globalData from '@/data/global.json';
import styles from '@/features/components/gameOne/gameOne.module.scss';
import ErrorAndSuccessScreen from '@/features/components/modal/errorAndSuccess/ErrorAndSuccess';
import ClickableItem from '@/features/devices/tablets/components/list/components/ClickableItem';
import TvActionHeader from '@/features/devices/tv/components/HUD/TvActionHeader';
import DisplayJobs from '@/features/devices/tv/components/jobs/DisplayJobs';
import JobImage from '@/features/devices/tv/components/jobs/JobImage';
import TvTextBanner from '@/features/devices/tv/components/tvTextBanner/TvTextBanner';
import useCurrentOverlay from '@/hooks/useCurrentOverlay';
import { useGame1Store } from '@/store/games/game1Store';
import {
    getSelectedLevers,
    getSelectedRisks,
} from '@/services/games/game1/gameOneUtils';
import { totalRisks } from '@/services/global/globalUtils';
import { OverlayType } from '@/types/global/types';
import { motion } from 'framer-motion';
import type { JobOmitGame1, RiskAndLever } from '@/types/games/game1/types';

const itemVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.3 } },
};

const GameOne: React.FC = () => {
    const {
        risks,
        risksFound: isRisksFound,
        leversFound: isLeversFound,
        job: currentJob,
        audioShouldPlay,
    } = useGame1Store();
    const { currentTextAudio, currentTitle, currentText, overlayType } =
        useCurrentOverlay();

    const selectedRisks = getSelectedRisks();
    const selectedLevers = getSelectedLevers();

    // The banner is shown if the risks are not all found or if there are risks in validation
    const showBanner =
        risks.length !== totalRisks ||
        (currentJob?.risksValidated && !currentText && !isLeversFound);

    const selectedItems = currentJob.risksValidated
        ? selectedLevers
        : selectedRisks;

    return (
        <div className={styles.gameOneContainer}>
            {!showBanner ? (
                <>
                    {audioShouldPlay ? (
                        <DisplayJobs />
                    ) : (
                        <>
                            {currentText ? (
                                <ErrorAndSuccessScreen
                                    color={
                                        overlayType === OverlayType.SUCCESS
                                            ? 'green'
                                            : 'red'
                                    }
                                    text={currentText}
                                    title={currentTitle}
                                    audio={currentTextAudio}
                                />
                            ) : (
                                <GameOneCoreComponent
                                    currentJob={currentJob}
                                    selectedItems={selectedItems}
                                    itemType={
                                        currentJob.risksValidated
                                            ? 'levers'
                                            : 'risks'
                                    }
                                />
                            )}
                        </>
                    )}
                </>
            ) : (
                <TvTextBanner
                    text={
                        isRisksFound
                            ? data.guideline.leversUnlockInstruction
                            : data.guideline.risksUnlockInstruction
                    }
                    currentNumber={isRisksFound ? undefined : risks.length}
                    totalNumber={isRisksFound ? undefined : totalRisks}
                    tabletName={globalData.global.red}
                />
            )}
        </div>
    );
};

export default GameOne;

const GameOneCoreComponent: React.FC<{
    currentJob: JobOmitGame1;
    selectedItems: RiskAndLever[];
    itemType: 'risks' | 'levers';
}> = ({ currentJob, selectedItems, itemType }) => (
    <>
        <TvActionHeader
            title={globalData.global.action}
            text={data.guideline.risks}
            additionnalStyle={{ margin: '40px' }}
        />
        <div className={styles.itemsContainer}>
            <h2>{currentJob?.name}</h2>

            <p>
                {itemType === 'risks'
                    ? globalData.tv.identifyRisks
                    : globalData.tv.identifyLevers}
            </p>

            <div>
                {currentJob[`${itemType}Id`].map((_, index) => {
                    return (
                        <motion.div variants={itemVariants} key={index}>
                            <ClickableItem
                                description={selectedItems[index]?.name}
                                isSelected={
                                    selectedItems[index]?.isSelected ?? false
                                }
                                empty={!selectedItems[index]}
                            />
                        </motion.div>
                    );
                })}
            </div>
        </div>
        <JobImage
            jobName={currentJob?.name ?? ''}
            imagePath={currentJob?.fullIcon ?? ''}
        />
    </>
);
