import WaitingTabletFooter from '@/features/devices/tv/components/HUD/WaitingTabletFooter';
import DisplayJobs from '@/features/devices/tv/components/jobs/DisplayJobs';
import globalData from '@/data/global.json';
import { type DeviceColor } from '@/types/global/types';
import {
    getSelectedRisks,
    getSelectedLevers,
} from '@/services/games/game1/gameOneUtils';
import { useOverlayStore } from '@/store/overlayStore';

interface ErrorAndSuccessScreenProps {
    title: string;
    text: string;
    color?: DeviceColor;
    audio?: string;
}

const ErrorAndSuccessScreen: React.FC<ErrorAndSuccessScreenProps> = ({
    color,
    title,
    text,
    audio,
}) => {
    const selectedRisks = getSelectedRisks();
    const selectedLevers = getSelectedLevers();

    const currentOverlayIndex = useOverlayStore.use.currentOverlayIndex();

    const risksOrLevers =
        selectedLevers.length > 0 ? selectedLevers : selectedRisks;

    return (
        <>
            <DisplayJobs
                color={color}
                title={`${risksOrLevers[currentOverlayIndex].name} - ${title}`}
                text={text}
                audio={audio}
            />
            <WaitingTabletFooter tablet={globalData.global.blue} />
        </>
    );
};

export default ErrorAndSuccessScreen;
