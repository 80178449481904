import dataGameFour from '@/data/gameFourData.json';
import { getJobsData } from '@/services/global/globalUtils';
import createSelectors from '@/store/selectors';
import { deepCopy } from '@/sessionInfo/sessionInfo';
import {
    type DemandStatus,
    type Game4Store,
    type JobGame4,
    type JobOmitGame4,
    type PossibleJobGame4Ids,
} from '@/types/games/game4/types';
import { type StrictOmit } from '@/types/global/types';
import { create } from 'zustand';

const NUMBER_OF_POSSIGLE_STATUS = 3;

export function initializeDefaultAnswersGame4() {
    const keys = Object.keys(dataGameFour.goodAnswers);
    const answers: Record<PossibleJobGame4Ids, DemandStatus[]> = {} as Record<
        PossibleJobGame4Ids,
        DemandStatus[]
    >;

    for (const key of keys) {
        answers[key as unknown as PossibleJobGame4Ids] = Array(
            NUMBER_OF_POSSIGLE_STATUS
        ).fill('Pas sélectionné' as DemandStatus);
    }

    return answers;
}

export const defaultJobs4 = getJobsData(dataGameFour.jobs) as StrictOmit<
    JobGame4,
    'jobId'
>[];

const initialState: Game4Store = {
    jobsGame4: defaultJobs4,
    currentAnswers: initializeDefaultAnswersGame4(),
    selectedJob: null,
};

const useGame4StoreBase = create(() => deepCopy(initialState));

export const useGame4Store = createSelectors(useGame4StoreBase);

export const setSelectedJobDemandingFactor = (job: JobOmitGame4 | null) => {
    useGame4StoreBase.setState({ selectedJob: job });
};

export const setCurrentAnswers = (
    jobId: PossibleJobGame4Ids,
    answerIndex: number,
    demandStatus: DemandStatus
) => {
    useGame4StoreBase.setState((state) => {
        const currentAnswers = { ...state.currentAnswers };
        currentAnswers[jobId][answerIndex] = demandStatus;
        return { currentAnswers };
    });
};

export const resetCurrentAnswers = () =>
    useGame4StoreBase.setState({
        currentAnswers: initializeDefaultAnswersGame4(),
    });

export const resetGame4 = () => useGame4StoreBase.setState(deepCopy(initialState));
