import styles from '@/features/devices/tv/components/jobs/jobImage.module.scss';

interface JobImageProps {
    imagePath: string;
    jobName: string;
}

const JobImage: React.FC<JobImageProps> = ({ imagePath, jobName }) => {
    const formattedJobName = jobName.replace(/ /g, '-').toLowerCase();
    const className = [
        'chauffeur-poids-lourd',
        'boucher',
        'agent-administratif',
    ].includes(formattedJobName)
        ? `${formattedJobName}`
        : 'regular-image';
    return (
        <div className={styles[className]}>
            <img src={imagePath} alt={jobName}></img>
        </div>
    );
};

export default JobImage;
