import dataGameOne from '@/data/gameOneData.json';
import MediatorAction from '@/features/devices/tablets/mediator/MediatorAction';
import {
    handleAllLeversFound,
    handleAllRisksFound,
    handleCurrentJobGame1,
    handleGoToNextOrPreviousJobOverlay,
    handleRisksAndLeversReset,
    handleUpdateGameOneAudioJobStatus,
    handleUpdateRisk,
    handleValidateGame1RisksAndLevers,
} from '@/services/games/game1/gameOneHandleFunctions';
import {
    getNextJobGame1,
    getPreviousJobGame1,
    getSelectedLevers,
    getSelectedRisks,
    isRiskFound,
} from '@/services/games/game1/gameOneUtils';
import { handleUpdateOverlayDescriptions } from '@/services/global/globalHandleFunctions';
import { getJobsData } from '@/services/global/globalUtils';
import { handleSelectAllRisksOrLeversForCurrentJob } from '@/services/mediator/mediatorUtils';
import { useGame1Store } from '@/store/games/game1Store';
import { useOverlayStore } from '@/store/overlayStore';
import { useEffect } from 'react';
import { type JobGame1 } from '@/types/games/game1/types';
import { OverlayType } from '@/types/global/types';

const GameOneMediator: React.FC = () => {
    const storedRisks = useGame1Store.use.risks();
    const currentJob = useGame1Store.use.job();
    const isRisksFound = useGame1Store.use.risksFound();
    const isLeversFound = useGame1Store.use.leversFound();
    const audioShouldPlay = useGame1Store.use.audioShouldPlay();

    const { currentOverlayIndex, overlayDescriptions } = useOverlayStore();
    const areRisksValidated = currentJob.risksValidated;
    const selectedRisks = getSelectedRisks();
    const selectedLevers = getSelectedLevers();

    const showValidateRisksOrLevers = areRisksValidated
        ? selectedLevers.length === currentJob.leversId.length
        : selectedRisks.length === currentJob.risksId.length;

    const nextJob = getNextJobGame1(getJobsData(dataGameOne.jobs) as JobGame1[]);
    const previousJob = getPreviousJobGame1(
        getJobsData(dataGameOne.jobs) as JobGame1[]
    );

    useEffect(() => {
        if (storedRisks.length !== dataGameOne.risks.length) return;
        handleAllRisksFound();
    }, [storedRisks.length]);

    return (
        <div>
            {/* Play the current job description */}
            <MediatorAction
                title='Jouer la description du métier'
                displayingCondition={
                    // Used to show the risks
                    (overlayDescriptions.length === 0 &&
                        isRisksFound &&
                        !areRisksValidated) ||
                    // Used to show the levers
                    (overlayDescriptions.length === 0 &&
                        areRisksValidated &&
                        isLeversFound)
                }
                handleFunction={() =>
                    handleUpdateGameOneAudioJobStatus(!audioShouldPlay)
                }
            />

            {/*  Go to next job */}
            <MediatorAction
                title='Passer au métier suivant'
                displayingCondition={isRisksFound && isLeversFound && !!nextJob}
                handleFunction={() => {
                    handleRisksAndLeversReset();
                    handleCurrentJobGame1(nextJob);
                }}
                type='tertiary'
            />

            {/* Go to previous job */}
            <MediatorAction
                title='Revenir au métier précédent'
                displayingCondition={isRisksFound && isLeversFound && !!previousJob}
                handleFunction={() => {
                    handleRisksAndLeversReset();
                    handleCurrentJobGame1(previousJob);
                    handleUpdateOverlayDescriptions([], OverlayType.NEUTRAL);
                }}
                type='tertiary'
            />

            {/* Next message */}
            <MediatorAction
                title='Passer au message suivant'
                displayingCondition={overlayDescriptions.length > 0}
                handleFunction={() => handleGoToNextOrPreviousJobOverlay('next')}
                type='secondary'
            />

            {/* Previous message */}
            <MediatorAction
                title='Revenir au message précédent'
                displayingCondition={
                    currentOverlayIndex > 0 && overlayDescriptions.length > 0
                }
                handleFunction={() =>
                    handleGoToNextOrPreviousJobOverlay('previous')
                }
                type='secondary'
            />

            {/* Unlock all risks */}
            <MediatorAction
                title='Débloquer tous les risques'
                displayingCondition={
                    overlayDescriptions.length === 0 &&
                    !isRisksFound &&
                    !isLeversFound
                }
                handleFunction={() => {
                    dataGameOne.risks.forEach((risk) => {
                        if (isRiskFound(risk)) return;
                        handleUpdateRisk({ ...risk, isSelected: false });
                    });
                }}
                type='secondary'
            />

            {/* Unlock risk */}
            {dataGameOne.risks.map((risk) => (
                <MediatorAction
                    key={risk.id}
                    title={`Risque: ${risk.name}`}
                    displayingCondition={!isRiskFound(risk)}
                    handleFunction={() =>
                        handleUpdateRisk({ ...risk, isSelected: false })
                    }
                    type='secondary'
                />
            ))}

            {/* Unlock levers */}
            <MediatorAction
                title='Débloquer les leviers'
                displayingCondition={
                    overlayDescriptions.length === 0 &&
                    isRisksFound &&
                    !isLeversFound &&
                    currentJob.risksValidated
                }
                handleFunction={handleAllLeversFound}
                type='secondary'
            />

            {/* Validate risks or levers */}
            <MediatorAction
                title={`Valider les ${areRisksValidated ? 'leviers' : 'risques'} pour: ${currentJob.name}`}
                displayingCondition={
                    overlayDescriptions.length === 0 && showValidateRisksOrLevers
                }
                handleFunction={() =>
                    handleValidateGame1RisksAndLevers(
                        areRisksValidated ? 'levers' : 'risks'
                    )
                }
                type='secondary'
            />

            {/* Select risk or levers current job */}
            <MediatorAction
                title={`Sélectionner les ${areRisksValidated ? 'leviers' : 'risques'} pour: ${currentJob.name}`}
                displayingCondition={
                    // Used to show the risks
                    (overlayDescriptions.length === 0 &&
                        isRisksFound &&
                        !areRisksValidated) ||
                    // Used to show the levers
                    (overlayDescriptions.length === 0 &&
                        areRisksValidated &&
                        isLeversFound)
                }
                handleFunction={() =>
                    handleSelectAllRisksOrLeversForCurrentJob(
                        areRisksValidated ? 'levers' : 'risks'
                    )
                }
                type='secondary'
            />
        </div>
    );
};

export default GameOneMediator;
