import dataGameTwo from '@/data/gameTwoData.json';
import { useGame2Store } from '@/store/games/game2Store';
import { ExposureStatus, type JobOmitGame2 } from '@/types/games/game2/types';

export const BADTHRESHOLD = 4;
export const MIDTHRESHOLD = 8;

/**
 * Check if a job is exposed.
 * @param jobId - The id of the job to check.
 * @returns Whether the job is exposed or not.
 */
export const isJobExposed = (job: JobOmitGame2): boolean => {
    return dataGameTwo.exposedJobsId.includes(job.id);
};

export const getExposedJobs = (): JobOmitGame2[] => {
    const jobs = useGame2Store.getState().jobsGame2;

    const { exposedJobsId } = dataGameTwo;
    return jobs.filter((job) => exposedJobsId.includes(job.id));
};

export const getNumberOfWellRepartedJobsGame2 = (): number => {
    const jobsExposure = useGame2Store.getState().jobsGame2;

    const { exposedJobsId } = dataGameTwo;
    const notExposedJobsId = jobsExposure
        .filter((job) => !exposedJobsId.includes(job.id))
        .map((job) => job.id);

    let wellRepartedJobs = 0;

    // A job is well reparted if it has EXPOSED status and it is in the exposedJobsId list
    // or if it has NOT_EXPOSED status and it is in the notExposedJobsId list
    jobsExposure.forEach((job) => {
        if (
            (job.status === ExposureStatus.EXPOSED &&
                exposedJobsId.includes(job.id)) ||
            (job.status === ExposureStatus.NOT_EXPOSED &&
                notExposedJobsId.includes(job.id))
        ) {
            wellRepartedJobs++;
        }
    });

    return wellRepartedJobs;
};

export const isJobWellReparted = (job: JobOmitGame2): boolean => {
    const { exposedJobsId } = dataGameTwo;
    const notExposedJobsId = useGame2Store
        .getState()
        .jobsGame2.filter((job) => !exposedJobsId.includes(job.id))
        .map((job) => job.id);

    return (
        (job.status === ExposureStatus.EXPOSED && exposedJobsId.includes(job.id)) ||
        (job.status === ExposureStatus.NOT_EXPOSED &&
            notExposedJobsId.includes(job.id))
    );
};
