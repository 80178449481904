import { useEffect } from 'react';

// scss
import tabletStyle from '@/features/devices/tablets/components/screens/tabletScreen.module.scss';

// components

import { Trickyberpunk } from '@/assets/images/global';
import Footer from '@/features/devices/tablets/components/HUD/Footer';
import Header from '@/features/devices/tablets/components/HUD/Header';
import ContraintSelection from '@/features/devices/tablets/components/contraintSelection/ContraintSelection';
import PlayAudio from '@/features/devices/tablets/components/playAudioJob/PlayAudioJob';
import useReSynchronizeDevice from '@/hooks/useReSynchronizeDevice';
import { useDeviceStore } from '@/store/deviceStore';
import { useGameStore } from '@/store/gameStore';
import { GAMESTATUS } from '@/types/global/types';
import { emitSocketEvent } from '@/services/global/globalUtils';

const OrangeActionSwitch: React.FC = () => {
    const gameStatus = useGameStore.use.gameStatus();
    const { orange } = useDeviceStore.use.deviceStatus();
    const isTabletInactive = !orange.isActivated;

    useReSynchronizeDevice('orange');

    useEffect(() => {
        document.title = `🟠 ${orange.label}`;
        emitSocketEvent('send_synchronize_device', {
            deviceColor: 'orange',
        });
    }, [orange.label]);

    const handleRender = () => {
        switch (gameStatus) {
            case GAMESTATUS.GAME1ACTION:
                return <PlayAudio />;
            case GAMESTATUS.GAME1PREVENTION:
            case GAMESTATUS.GAME4ACTION:
                return <ContraintSelection />;
            case GAMESTATUS.FINISHED:
                break;
        }
    };

    return (
        <div className={tabletStyle.orangeTabletContainer}>
            {isTabletInactive ? (
                <>
                    <Header />
                    <img
                        src={Trickyberpunk}
                        alt='Logo tricky'
                        className={tabletStyle.trickyberpunk}
                    />
                    <Footer />
                </>
            ) : (
                <>{handleRender()}</>
            )}
        </div>
    );
};

export default OrangeActionSwitch;
