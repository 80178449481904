import { motion } from 'framer-motion';
import AudioComponent from '@/features/components/AudioComponent';
import styles from '@/features/components/gameThree/belief.module.scss';
import TvActionHeader from '@/features/devices/tv/components/HUD/TvActionHeader';
import WaitingTabletFooter from '@/features/devices/tv/components/HUD/WaitingTabletFooter';
import useCurrentOverlay from '@/hooks/useCurrentOverlay';
import gameTwoData from '@/data/gameTwoData.json';
import { useGame3Store } from '@/store/games/game3Store';
import { isObjectEmpty } from '@/services/global/globalUtils';
import { OverlayType } from '@/types/global/types';
import globalData from '@/data/global.json';

const slideVariants = {
    hidden: { x: 800 },
    visible: { x: 0, transition: { duration: 0.8 } },
    exit: { x: 400, transition: { duration: 0.8 } },
};

const fadeZoomVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
    exit: { opacity: 0, scale: 0.8, transition: { duration: 0.5 } },
};

const Belief: React.FC = () => {
    const selectedJob = useGame3Store.use.selectedJob();
    const selectedArgument = useGame3Store.use.selectedArgument();
    const { currentText, overlayType, currentTextAudio } = useCurrentOverlay();

    const handleOverlayColor = () => {
        switch (overlayType) {
            case OverlayType.SUCCESS:
                return 'green';
            case OverlayType.ERROR:
                return 'red';
            default:
                return 'white';
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.leftContainer}>
                <div className={styles.header}>
                    <TvActionHeader
                        title={globalData.global.action}
                        text={gameTwoData.guideline.belief}
                        border
                    />
                </div>
                {!isObjectEmpty(selectedArgument) && selectedArgument != null && (
                    <motion.div
                        className={styles.wrapper}
                        variants={fadeZoomVariants}
                        initial='hidden'
                        animate='visible'
                        exit='exit'
                    >
                        <TvActionHeader
                            title={
                                currentText
                                    ? currentText
                                    : globalData.tv.selectCharacter
                            }
                            border
                            information={
                                selectedArgument?.name ?? globalData.global.waiting
                            }
                            color={handleOverlayColor()}
                        />
                        <AudioComponent src={currentTextAudio} />
                    </motion.div>
                )}
            </div>
            {!isObjectEmpty(selectedJob) && selectedJob != null && (
                <div className={styles.rightContainer}>
                    <motion.img
                        key={selectedJob.id}
                        src={selectedJob?.fullIcon}
                        alt={selectedJob?.slug}
                        className={`${styles.character} ${styles[selectedJob?.slug]}`}
                        variants={slideVariants}
                        initial='hidden'
                        animate='visible'
                        exit='exit'
                    />
                    <div className={styles.bubble}>
                        <div>
                            <AudioComponent
                                src={selectedJob.audioPath ?? ''}
                                trackSource={selectedJob?.description}
                            />
                            <h1>
                                {selectedJob.firstName} - {selectedJob.name}{' '}
                            </h1>
                            <p>{selectedJob?.description}</p>
                        </div>
                    </div>
                </div>
            )}
            <WaitingTabletFooter tablet='verte' />
        </div>
    );
};

export default Belief;
