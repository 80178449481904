import { motion, useAnimation } from 'framer-motion';
import { useEffect } from 'react';
import styles from '@/features/devices/tv/components/demandingFactors/demandingFactorRecap.module.scss';

interface DemandingFactorRecapProps {
    color: string;
    content?: string;
    iconPath: string;
}

const DemandingFactorRecap: React.FC<DemandingFactorRecapProps> = ({
    color,
    content,
    iconPath,
}) => {
    const controls = useAnimation();

    useEffect(() => {
        controls.set({ height: 23, opacity: 0, scale: 0.6 });
        controls.start({ opacity: 1, scale: 1 });
    }, [color, controls]);

    if (content) {
        if (content === 'Postures contraignantes') content = 'Postures cont.';
    }
    const containerClass = color ? styles[`${color}Container`] : '';

    return (
        <motion.div
            className={containerClass}
            animate={controls}
            transition={{ duration: 0.3 }}
            style={{ visibility: color ? 'visible' : 'hidden' }}
        >
            <img src={iconPath} alt='Icône' />
            {content && <p>{content.toUpperCase()}</p>}
        </motion.div>
    );
};

export default DemandingFactorRecap;
