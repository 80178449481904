import { motion } from 'framer-motion';
import styles from '@/features/devices/tablets/components/fullScreenButton/fullScreenButton.module.scss';

interface FullScreenButtonProps {
    onClick: () => void;
    gradientColor: string;
    text: string;
    subtext: string;
    colorOfSubtext: string;
}

const FullScreenButton: React.FC<FullScreenButtonProps> = ({
    onClick,
    gradientColor,
    text,
    subtext,
    colorOfSubtext,
}) => {
    return (
        <motion.button
            onClick={onClick}
            whileTap={{ scale: 0.95 }}
            className={` ${styles[`${gradientColor}Container`]}`}
        >
            <div>{text}</div>
            <p className={` ${styles[`${colorOfSubtext}Text`]}`}>{subtext}</p>
        </motion.button>
    );
};

export default FullScreenButton;
