import styles from '@/features/devices/tablets/mediator/preConnectionMediatorScreen.module.scss';

export interface TabletStatus {
    label: string;
    status: boolean;
    color: string;
}

interface TabletStatusListProps {
    list: TabletStatus[];
    displayOffline?: boolean;
}

const TabletStatusList: React.FC<TabletStatusListProps> = ({
    list,
    displayOffline = false,
}) => {
    return list.map(({ label, color }) => (
        <p key={label} className={displayOffline ? styles.offline : ''}>
            <span
                className={`${styles['tablet-color-' + color.toLowerCase()]}`}
            ></span>
            {label}
        </p>
    ));
};

export default TabletStatusList;
