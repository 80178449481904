import styles from '@/features/devices/tv/components/HUD/tvActionHeader.module.scss';

interface TvActionHeaderProps {
    title?: string;
    text?: string;
    border?: boolean;
    color?: string;
    icon?: string;
    numbers?: string;
    information?: string;
    isList?: boolean;
    additionnalStyle?: React.CSSProperties;
}

const TvActionHeader: React.FC<TvActionHeaderProps> = ({
    title,
    text,
    border,
    color = 'white',
    icon,
    numbers,
    information,
    isList,
    additionnalStyle,
}) => {
    const handleTextForm = () => {
        if (isList) {
            const listOfString = text?.split(', ');
            return (
                <>
                    <p className={styles.daily}>Mon quotidien :</p>
                    <ul>
                        {listOfString?.map((stringElement) => (
                            <li key={stringElement}>{stringElement}</li>
                        ))}
                    </ul>
                </>
            );
        } else {
            return <p className={styles.text}>{text}</p>;
        }
    };

    return (
        <div>
            <div
                className={`${styles[`${color}Container`]} ${
                    border && styles[`${color}Border`]
                }`}
                style={{ ...additionnalStyle }}
            >
                <div>
                    {icon && <img src={icon} alt='icône' />}
                    <h2 className={styles.title}>{title}</h2>
                    {numbers && <p className={styles.numbers}>{`${numbers}`}</p>}
                </div>
                {handleTextForm()}
            </div>
            {information && (
                <div className={`${styles[`${color}Information`]}`}>
                    <p>{information}</p>
                </div>
            )}
        </div>
    );
};

export default TvActionHeader;
