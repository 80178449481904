import styles from '@/features/devices/tablets/components/inputUnluckCode/inputUnlockCode.module.scss';
import { SHAKE_ANIMATION } from '@/services/global/globalUtils';

import { motion, useAnimation } from 'framer-motion';
import { useEffect, useState } from 'react';

const CODE_LENGTH = 4;

export const DELAY_FACTOR = 0.1;

const InputUnlockCode: React.FC<{ code: string }> = ({ code }) => {
    const controls = useAnimation();
    const [isComponentFirstRender, setIsComponentFirstRender] = useState(true);

    useEffect(() => {
        if (isComponentFirstRender) {
            setIsComponentFirstRender(false);
            return;
        }
        if (code === '') {
            controls.start(SHAKE_ANIMATION);
        }
    }, [controls, code, isComponentFirstRender]);

    return (
        <div className={styles.container}>
            <motion.div animate={controls}>
                {Array.from({ length: CODE_LENGTH }).map((_, index) => (
                    <motion.div
                        key={index}
                        className={code[index] ? styles.digit : styles.empty}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: index * DELAY_FACTOR }}
                    >
                        {code[index] || ''}
                    </motion.div>
                ))}
            </motion.div>
        </div>
    );
};

export default InputUnlockCode;
