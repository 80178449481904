import MediatorAction from '@/features/devices/tablets/mediator/MediatorAction';
import useCurrentOverlay from '@/hooks/useCurrentOverlay';
import gameFourData from '@/data/gameFourData.json';
import { useGame4Store } from '@/store/games/game4Store';
import {
    handleResetCurrentAnswers,
    handleSelectCurrentAnswer,
    handleSelectGame4Job,
    handleValidateGame4,
} from '@/services/games/game4/gameFourHandleFunctions';
import { areAllJobDemandingFactorCompleted } from '@/services/games/game4/gameFourUtils';
import {
    type DemandStatus,
    type PossibleJobGame4Ids,
} from '@/types/games/game4/types';
import { areArraysEqual } from '@/services/global/globalUtils';

const gameFourCorrectAnswers = gameFourData.goodAnswers;

const GameFourMediator: React.FC = () => {
    const jobsDemandingFactor = useGame4Store.use.jobsGame4();
    const currentAnswers = useGame4Store.use.currentAnswers();

    const { currentText } = useCurrentOverlay();

    return (
        <div>
            {/* Send the good answer for all jobs */}
            <MediatorAction
                title='Bonne combinaison pour tous les métiers'
                displayingCondition={
                    !areArraysEqual(
                        Object.values(currentAnswers).flat(),
                        Object.values(gameFourCorrectAnswers).flat()
                    )
                }
                handleFunction={() => {
                    jobsDemandingFactor.forEach((job) => {
                        const goodAnswer = Object.keys(gameFourCorrectAnswers).find(
                            (answer) => parseInt(answer) === job.id
                        )!;

                        const goodAnswerValue = gameFourCorrectAnswers[
                            goodAnswer as keyof typeof gameFourCorrectAnswers
                        ] as DemandStatus[];

                        handleSelectGame4Job(job);
                        goodAnswerValue.forEach((answer, index) => {
                            handleSelectCurrentAnswer(
                                job.id as PossibleJobGame4Ids,
                                index,
                                answer
                            );
                        });
                    });
                }}
                type='secondary'
            />

            {/* Send the good answer for a single job */}
            {jobsDemandingFactor.map((job) => {
                const goodAnswer = Object.keys(gameFourCorrectAnswers).find(
                    (answer) => parseInt(answer) === job.id
                )!;

                const goodAnswerValue = gameFourCorrectAnswers[
                    goodAnswer as keyof typeof gameFourCorrectAnswers
                ] as DemandStatus[];

                return (
                    <MediatorAction
                        key={job.id}
                        title={`Bonne combinaison pour ${job.firstName}`}
                        displayingCondition={
                            !areArraysEqual(
                                currentAnswers[job.id as PossibleJobGame4Ids],
                                goodAnswerValue
                            )
                        }
                        handleFunction={() => {
                            handleSelectGame4Job(job);
                            goodAnswerValue.forEach((answer, index) => {
                                handleSelectCurrentAnswer(
                                    job.id as PossibleJobGame4Ids,
                                    index,
                                    answer
                                );
                            });
                        }}
                        type='secondary'
                    />
                );
            })}

            {/* Validate the selection */}
            <MediatorAction
                title='Valider la sélection'
                displayingCondition={
                    !currentText && areAllJobDemandingFactorCompleted()
                }
                handleFunction={handleValidateGame4}
            />

            {/* Reset all the exposure status */}
            <MediatorAction
                title='Réinitialiser les combinaisons'
                displayingCondition
                handleFunction={handleResetCurrentAnswers}
                type='tertiary'
            />
        </div>
    );
};

export default GameFourMediator;
