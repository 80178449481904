import { motion } from 'framer-motion';
import type React from 'react';
import { Check, Uncheck } from '@/assets/images/icons';
import styles from '@/features/devices/tablets/components/list/components/clickableItem.module.scss';

interface ClickableItemProps {
    icon?: string;
    description?: string;
    onClick?: () => void;
    empty?: boolean;
    disabled?: boolean;
    isSelected?: boolean;
}

const ClickableItem: React.FC<ClickableItemProps> = ({
    icon,
    description,
    onClick,
    empty,
    disabled,
    isSelected,
}) => {
    const getClassName = () => {
        if (isSelected) return styles.checkedContainer;
        if (empty) return styles.emptyContainer;
        return styles.uncheckedContainer;
    };

    const onClickFunction = () => {
        if (onClick) onClick();
    };

    return (
        <motion.button
            onClick={onClickFunction}
            whileTap={{ scale: 0.9 }}
            className={getClassName()}
            disabled={disabled}
        >
            <label>
                <img src={isSelected ? Check : Uncheck} alt='checkbox' />
                {icon && <img src={icon} alt='icon' />}
                <p>{description}</p>
            </label>
        </motion.button>
    );
};

export default ClickableItem;
