import dataGameOne from '@/data/gameOneData.json';
import mediatorDatas from '@/data/mediator/actionsGames.json';
import { useGame1Store } from '@/store/games/game1Store';

import {
    handleRisksAndLeversReset,
    handleUpdateRiskOrLeverStatus,
} from '@/services/games/game1/gameOneHandleFunctions';
import type { ActionsGamesMapping } from '@/types/mediator/types';

export const actionsGames: ActionsGamesMapping = mediatorDatas;

export const handleSelectAllRisksOrLeversForCurrentJob = (
    type: 'risks' | 'levers'
): void => {
    const currentJob = useGame1Store.getState().job;
    const items = useGame1Store.getState()[type];
    const correctItemsId = dataGameOne.jobs.find(
        (job) => job.jobId === currentJob.id
    )![`${type}Id`];

    // Set all the items that have ids in the correctItemsId array to isSelected
    handleRisksAndLeversReset();
    items.forEach((item) => {
        if (correctItemsId.includes(item.id))
            handleUpdateRiskOrLeverStatus(item.id, type, true);
    });
};
