import gameFourData from '@/data/gameFourData.json';
import { numberOfCorrectAnswers } from '@/services/games/game4/gameFourUtils';
import {
    type DemandStatus,
    type JobOmitGame4,
    type PossibleJobGame4Ids,
} from '@/types/games/game4/types';
import {
    handleTabletStatus,
    handleUpdateOverlayDescriptions,
} from '@/services/global/globalHandleFunctions';
import { emitSocketEvent } from '@/services/global/globalUtils';
import { type OverlayDescription, OverlayType } from '@/types/global/types';

export const handleSelectGame4Job = (currentJob: JobOmitGame4 | null): void => {
    handleTabletStatus({
        blue: false,
        red: false,
        green: true,
        orange: true,
    });
    emitSocketEvent('send_select_job_4', {
        currentJob,
    });
};

export const handleSelectCurrentAnswer = (
    selectedJobId: PossibleJobGame4Ids,
    index: number,
    nextStatus: DemandStatus
): void => {
    emitSocketEvent('send_select_current_answer', {
        selectedJobId,
        index,
        nextStatus,
    });
};

export const handleResetCurrentAnswers = (): void => {
    emitSocketEvent('send_reset_current_answers', {});
};

export const handleValidateGame4 = (): void => {
    const correctAnswers = gameFourData.goodAnswers;
    const badThreshold = Object.keys(correctAnswers).length / 4;
    const mediumThreshold = Object.keys(correctAnswers).length / 2;

    const numberOfGoodAnswers = numberOfCorrectAnswers();

    const computeOverlayType = (): OverlayType => {
        if (numberOfGoodAnswers <= badThreshold) return OverlayType.ERROR;
        if (numberOfGoodAnswers <= mediumThreshold) return OverlayType.NEUTRAL;
        return OverlayType.SUCCESS;
    };

    const computeOverlayDescription = (): OverlayDescription[] => {
        if (numberOfGoodAnswers <= badThreshold)
            return gameFourData.recapOverlay.bad;
        if (numberOfGoodAnswers <= mediumThreshold)
            return gameFourData.recapOverlay.mid;
        return gameFourData.recapOverlay.good;
    };

    handleTabletStatus({
        blue: true,
        red: false,
        green: false,
        orange: false,
    });

    handleUpdateOverlayDescriptions(
        computeOverlayDescription(),
        computeOverlayType()
    );
};
