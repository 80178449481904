import styles from '@/features/devices/tablets/components/HUD/header.module.scss';
import data from '@/data/global.json';

const Header: React.FC = () => {
    return (
        <div className={styles.container}>
            <p>{data.tablet.hud.title}</p>
            <p>{data.tablet.hud.subtitle}</p>
        </div>
    );
};

export default Header;
