import styles from '@/features/components/gameTwo/exposed.module.scss';
import JobContainerIcon from '@/features/devices/tv/components/jobs/JobContainerIcon';
import { AnimatePresence, motion } from 'framer-motion';

import AudioComponent from '@/features/components/AudioComponent';
import TvActionHeader from '@/features/devices/tv/components/HUD/TvActionHeader';
import WaitingTabletFooter from '@/features/devices/tv/components/HUD/WaitingTabletFooter';
import gameTwoData from '@/data/gameTwoData.json';
import globalData from '@/data/global.json';
import { useGame2Store } from '@/store/games/game2Store';

const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: { staggerChildren: 0.2, delayChildren: 0.5 },
    },
};

const itemVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.3 } },
};

const imageVariants = {
    hidden: { opacity: 0, x: -400 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
    exit: { opacity: 0, x: 400, transition: { duration: 0.5 } },
};

const Exposed: React.FC = () => {
    const jobsExposure = useGame2Store.use.jobsGame2();
    const selectedJob = useGame2Store.use.selectedJob();

    return (
        <div className={styles.container}>
            <div className={styles.leftContainer}>
                <div className={styles.header}>
                    <TvActionHeader
                        title={globalData.global.action}
                        text={gameTwoData.guideline.exposed}
                        border
                    />
                </div>
                {selectedJob && (
                    <div className={styles.wrapper}>
                        <AudioComponent
                            src={selectedJob?.audioPath}
                            trackSource={selectedJob?.description}
                        />
                        <TvActionHeader
                            title={
                                selectedJob?.firstName + ' - ' + selectedJob?.name
                            }
                            text={selectedJob?.description}
                            color='green'
                            border
                        />
                    </div>
                )}
            </div>
            <AnimatePresence>
                {selectedJob && (
                    <motion.img
                        key={selectedJob.id}
                        src={selectedJob?.fullIcon}
                        alt={selectedJob?.slug}
                        className={styles.character}
                        variants={imageVariants}
                        initial='hidden'
                        animate='visible'
                        exit='exit'
                    />
                )}
            </AnimatePresence>
            <div className={styles.rightContainer}>
                <h1>{globalData.tv.actualRepartition}</h1>
                <motion.div
                    variants={containerVariants}
                    initial='hidden'
                    animate='visible'
                >
                    {jobsExposure.map((job) => (
                        <motion.div variants={itemVariants} key={job.id}>
                            <JobContainerIcon
                                icon={job.icon}
                                exposureStatus={job.status}
                            />
                        </motion.div>
                    ))}
                </motion.div>
            </div>
            <WaitingTabletFooter tablet={globalData.global.green} />
        </div>
    );
};

export default Exposed;
