import { motion } from 'framer-motion';

import { playSound } from '@/services/global/globalHandleFunctions';

import styles from '@/features/devices/tablets/components/buttons/button.module.scss';
import { useGameStore } from '@/store/gameStore';
import { type DeviceColor } from '@/types/global/types';
import { isDevelopperMode } from '@/services/global/globalUtils';
import { Boop } from '@/assets/sounds/soundsEffects';

interface Button {
    text: string;
    color: DeviceColor;
    onClick: () => void;
    additionalStyle?: React.CSSProperties;
    disabled?: boolean;
    soundPath?: string;
}

const Button: React.FC<Button> = ({
    text,
    color,
    onClick,
    additionalStyle,
    disabled,
    soundPath,
}) => {
    const isAudioPlaying = useGameStore.use.isAudioPlaying();

    return (
        <motion.button
            className={`${styles.btn} ${styles[`${color}-btn`]}`}
            style={{ ...additionalStyle }}
            type='button'
            onClick={() => {
                onClick();
                playSound(soundPath ?? Boop);
            }}
            disabled={disabled || (!isDevelopperMode && isAudioPlaying)}
            whileTap={{
                scale: 0.9,
            }}
        >
            {text}
        </motion.button>
    );
};

export default Button;
