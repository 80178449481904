import data from '@/data/gameOneData.json';
import { useGame1Store } from '@/store/games/game1Store';
import {
    type JobGame1,
    type JobOmitGame1,
    type Lever,
    type Risk,
} from '@/types/games/game1/types';

export const isRiskFound = (risk: Risk) => {
    const risks = useGame1Store.getState().risks;
    return risks.find((r: { id: number; }) => r.id === risk.id);
};

/**
 * Get the risk corresponding to the code.
 * @param code The code of the risk to get.
 * @returns The risk corresponding to the code.
 */
export const getRiskFromCode = (code: string): Risk | undefined => {
    return data.risks.find((risk) => risk.correctCode === code);
};

/**
 * @returns The risks that are currently selected by the players or the game master.
 */
export const getSelectedRisks = (): Risk[] => {
    const risks = useGame1Store.use.risks();
    return risks.filter((risk: { isSelected: any; }) => risk.isSelected);
};

/**
 * @returns The levers that are currently selected by the players or the game master.
 */
export const getSelectedLevers = (): Lever[] => {
    const levers = useGame1Store.use.levers();
    return levers.filter((lever: { isSelected: any; }) => lever.isSelected);
};

/**
 * Returns the next job in game 1.
 * @param {JobGame1[]} jobs - The jobs in game 1.
 * @returns {JobOmitGame1} The next job in game 1.
 */
export const getNextJobGame1 = (jobs: JobGame1[]): JobOmitGame1 => {
    const currentJob = useGame1Store.getState().job;

    const indexCurrentJob = jobs.findIndex((job) => job.id === currentJob.id);
    return jobs[indexCurrentJob + 1];
};

export const getPreviousJobGame1 = (jobs: JobGame1[]): JobOmitGame1 => {
    const currentJob = useGame1Store.getState().job;

    const indexCurrentJob = jobs.findIndex((job) => job.id === currentJob.id);
    return jobs[indexCurrentJob - 1];
};
