import { motion, useCycle } from 'framer-motion';
import { useEffect } from 'react';

import styles from '@/features/devices/tablets/components/progressBar/progressBar.module.scss';

type ProgressBarProps = {
    title?: string;
    numberOfItemCompleted: number;
    totalNumberOfItems: number;
};

const ProgressBar: React.FC<ProgressBarProps> = ({
    title,
    numberOfItemCompleted = 0,
    totalNumberOfItems = 1,
}) => {
    const progress = (numberOfItemCompleted / totalNumberOfItems) * 100;

    const [glow, cycleGlow] = useCycle(
        '0px 0px 10px 2px #6cfb48',
        '0px 0px 20px 3px #6cfb48'
    );

    useEffect(() => {
        const interval = setInterval(() => {
            cycleGlow();
        }, 2000);
        return () => clearInterval(interval);
    }, [cycleGlow]);

    return (
        <div className={styles.title}>
            {title && (
                <div>
                    <h2>{title}</h2>
                    <h2>{`${numberOfItemCompleted}/${totalNumberOfItems}`}</h2>
                </div>
            )}
            <div className={styles.bar}>
                <motion.div
                    className={styles.progress}
                    initial={{ width: 0 }}
                    animate={{ width: `${progress}%`, boxShadow: glow }}
                    transition={{ duration: 1, ease: 'easeInOut' }}
                />
            </div>
        </div>
    );
};

export default ProgressBar;
