import styles from '@/features/devices/tablets/components/HUD/footer.module.scss';
import data from '@/data/global.json';

const Footer: React.FC = () => {
    return (
        <div className={styles.container}>
            <div>
                <p>{data.tablet.hud.firstLineStart}</p>
                <p>{data.tablet.hud.secondLineStart}</p>
                <p>{data.tablet.hud.thirdLineStart}</p>
            </div>
            <div>
                <p>{data.tablet.hud.firstLineEnd}</p>
                <p>{data.tablet.hud.secondLineEnd}</p>
                <p>{data.tablet.hud.thirsLineEnd}</p>
            </div>
        </div>
    );
};

export default Footer;
