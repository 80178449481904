import MediatorAction from '@/features/devices/tablets/mediator/MediatorAction';
import dataGameTwo from '@/data/gameTwoData.json';
import { useGame2Store } from '@/store/games/game2Store';
import { useOverlayStore } from '@/store/overlayStore';
import {
    handleUpdateJobGame2,
    handleValidateGame2,
} from '@/services/games/game2/gameTwoHandleFunctions';
import { ExposureStatus } from '@/types/games/game2/types';
import { isJobWellReparted } from '@/services/games/game2/gameTwoUtils';

const GameTwoMediator: React.FC = () => {
    const jobsExposure = useGame2Store.use.jobsGame2();

    const { overlayDescriptions } = useOverlayStore();

    return (
        <div>
            {/* Send the good combination for all jobs */}
            <MediatorAction
                title='Bonne combinaison pour tous'
                displayingCondition={
                    jobsExposure.some(
                        (job) => job.status === ExposureStatus.NOT_SELECTED
                    ) && overlayDescriptions.length === 0
                }
                handleFunction={() => {
                    jobsExposure.forEach((job) => {
                        let exposureStatus: keyof typeof ExposureStatus;
                        if (dataGameTwo.exposedJobsId.includes(job.id))
                            exposureStatus = ExposureStatus.EXPOSED;
                        else exposureStatus = ExposureStatus.NOT_EXPOSED;
                        handleUpdateJobGame2(job, exposureStatus);
                    });
                }}
                type='secondary'
            />

            {/* Send for each job, the good exposure status */}
            {jobsExposure.map((job) => {
                let exposureStatus: keyof typeof ExposureStatus;
                if (dataGameTwo.exposedJobsId.includes(job.id))
                    exposureStatus = ExposureStatus.EXPOSED;
                else exposureStatus = ExposureStatus.NOT_EXPOSED;
                return (
                    <MediatorAction
                        key={job.id}
                        title={`Bonne combinaison pour ${job.firstName}`}
                        displayingCondition={
                            job.status === ExposureStatus.NOT_SELECTED ||
                            !isJobWellReparted(job)
                        }
                        handleFunction={() => {
                            handleUpdateJobGame2(job, exposureStatus);
                        }}
                        type='secondary'
                    />
                );
            })}

            {/* Validate the selection */}
            <MediatorAction
                title='Valider la sélection'
                displayingCondition={
                    jobsExposure.every(
                        (job) => job.status !== ExposureStatus.NOT_SELECTED
                    ) && overlayDescriptions.length === 0
                }
                handleFunction={handleValidateGame2}
            />

            {/* Reset all the exposure status */}
            <MediatorAction
                title='Réinitialiser les combinaisons'
                displayingCondition={
                    jobsExposure.some(
                        (job) => job.status !== ExposureStatus.NOT_SELECTED
                    ) && overlayDescriptions.length === 0
                }
                handleFunction={() => {
                    jobsExposure.forEach((job) => {
                        if (job.status !== ExposureStatus.NOT_SELECTED) {
                            handleUpdateJobGame2(job, ExposureStatus.NOT_SELECTED);
                        }
                    });
                }}
                type='tertiary'
            />
        </div>
    );
};

export default GameTwoMediator;
