import createSelectors from '@/store/selectors';
import { deepCopy, INITAL_OVERLAY_STORE } from '@/sessionInfo/sessionInfo';
import { type OverlayDescription, type OverlayType } from '@/types/global/types';
import { create } from 'zustand';

const useOverlayStoreBase = create(() => deepCopy(INITAL_OVERLAY_STORE));

export const useOverlayStore = createSelectors(useOverlayStoreBase);

export const setCurrentOverlayIndex = (index: number): void => {
    useOverlayStoreBase.setState({
        currentOverlayIndex: index,
    });
};

export const setCurrentOverlayDescriptions = (
    overlayDescriptions: OverlayDescription[],
    type: OverlayType
): void => {
    useOverlayStoreBase.setState({
        overlayDescriptions,
        overlayType: type,
        currentOverlayIndex: 0,
    });
};

export const setCurrentOverlayType = (type: OverlayType): void => {
    useOverlayStoreBase.setState({
        overlayType: type,
    });
};

export const resetOverlays = (): void => {
    useOverlayStoreBase.setState(deepCopy(INITAL_OVERLAY_STORE));
};
