import styles from '@/features/devices/tv/components/HUD/waitingTabletFooter.module.scss';
import { motion } from 'framer-motion';
import type React from 'react';
import globalData from '@/data/global.json';

interface waitingTabletFooterProps {
    tablet?: string;
}
const WaitingTabletFooter: React.FC<waitingTabletFooterProps> = ({ tablet }) => {
    const fadeInVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 },
    };

    return (
        <motion.div
            className={styles.modalFadeHidden}
            initial='hidden'
            animate='visible'
            variants={fadeInVariants}
            transition={{ duration: 1 }}
        >
            <div>
                <p>
                    {globalData.global.waitingTablet} {tablet}
                </p>
            </div>
        </motion.div>
    );
};

export default WaitingTabletFooter;
