import Conversation from '@/features/components/modal/carouselConversation/Conversation';
import styles from '@/features/components/modal/carouselConversation/carouselConversation.module.scss';
import useCurrentOverlay from '@/hooks/useCurrentOverlay';
import { AnimatePresence, motion } from 'framer-motion';

const CarouselConversation: React.FC = () => {
    const { currentTextAudio, currentTitle, currentText } = useCurrentOverlay();

    const variants = {
        entering: { x: '100%', opacity: 0 },
        entered: { x: '0%', opacity: 1 },
        exiting: { x: '-100%', opacity: 0 },
    };

    return (
        <AnimatePresence mode='wait'>
            {currentText && (
                <motion.div
                    key={currentText}
                    className={styles.conversation}
                    initial='entering'
                    animate='entered'
                    exit='exiting'
                    variants={variants}
                    transition={{ type: 'spring', stiffness: 100, damping: 20 }}
                >
                    <Conversation
                        title={currentTitle}
                        text={currentText}
                        audio={currentTextAudio}
                    />
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default CarouselConversation;
