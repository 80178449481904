import { EndScreen, MainScreen } from '@/assets/images/global';
import { Ambiance } from '@/assets/sounds/soundsEffects';
import styles from '@/assets/styles/app.module.scss';
import GameFour from '@/features/components/gameFour/GameFour';
import GameOne from '@/features/components/gameOne/GameOne';
import GameThree from '@/features/components/gameThree/GameThree';
import GameTwo from '@/features/components/gameTwo/GameTwo';
import CarouselConversation from '@/features/components/modal/carouselConversation/CarouselConversation';
import ConfirmOverlay from '@/features/devices/tv/components/confirmOverlay/ConfirmOverlay';
import Prevention from '@/features/devices/tv/components/prevention/Prevention';
import useReSynchronizeDevice from '@/hooks/useReSynchronizeDevice';
import { emitSocketEvent } from '@/services/global/globalUtils';
import { useGameStore } from '@/store/gameStore';
import { useEffect, useState } from 'react';
import { GAMESTATUS } from '@/types/global/types';

const Tv: React.FC = () => {
    const [isFirstRender, setIsFirstRender] = useState(true);
    const gameStatus = useGameStore.use.gameStatus();
    const isGamePrevention =
        gameStatus ===
        (GAMESTATUS.GAME1PREVENTION ||
            GAMESTATUS.GAME3PREVENTION ||
            GAMESTATUS.GAME4PREVENTION);

    const isFinished = gameStatus === GAMESTATUS.FINISHED;
    useReSynchronizeDevice('tv');

    useEffect(() => {
        emitSocketEvent('send_synchronize_device', {
            deviceColor: 'tv',
        });
    }, []);

    useEffect(() => {
        document.title = '🖥️ TV';

        if (gameStatus === GAMESTATUS.WELCOME_SCREEN) return;
        if (!isFirstRender) return;

        const ambianceSound = new Audio(Ambiance);
        ambianceSound.loop = true;
        ambianceSound.volume = 0.2;
        ambianceSound.play();
        setIsFirstRender(false);
    }, [gameStatus, isFirstRender]);

    const handleRender = () => {
        switch (gameStatus) {
            case GAMESTATUS.WELCOME_SCREEN:
            case GAMESTATUS.FINISHED:
                return (
                    <img
                        src={isFinished ? EndScreen : MainScreen}
                        alt="écran d'accueil ou de fin"
                    />
                );
            case GAMESTATUS.INTRODUCTION:
            case GAMESTATUS.GAME1INTRODUCTION:
            case GAMESTATUS.GAME2INTRODUCTION:
            case GAMESTATUS.GAME3INTRODUCTION:
            case GAMESTATUS.GAME4INTRODUCTION:
                return <CarouselConversation />;
            case GAMESTATUS.GAME1ACTION:
                return <GameOne />;
            case GAMESTATUS.GAME2ACTION:
                return <GameTwo />;
            case GAMESTATUS.GAME3ACTION:
                return <GameThree />;
            case GAMESTATUS.GAME4ACTION:
                return <GameFour />;
            case GAMESTATUS.GAME1PREVENTION:
            case GAMESTATUS.GAME3PREVENTION:
            case GAMESTATUS.GAME4PREVENTION:
                return <Prevention />;
            case GAMESTATUS.GAME1UNLOCK:
            case GAMESTATUS.GAME3UNLOCK:
            case GAMESTATUS.GAME4UNLOCK:
                return <ConfirmOverlay />;
        }
    };

    return (
        <div
            className={
                isGamePrevention ? styles.tvContainerPrevention : styles.tvContainer
            }
        >
            {handleRender()}
        </div>
    );
};

export default Tv;
