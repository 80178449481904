import UnlockLevers from '@/features/devices/tablets/components/keypad/UnlockLevers';
import UnlockRisks from '@/features/devices/tablets/components/keypad/UnlockRisks';
import { useGame1Store } from '@/store/games/game1Store';

/**
 * The maximum length of the unlock code for both risks and levers.
 */
export const MAX_CODE_LENGTH = 4;

const DigitKeypadScreen: React.FC = () => {
    const risksFound = useGame1Store.use.risksFound();
    return <>{risksFound ? <UnlockLevers /> : <UnlockRisks />}</>;
};

export default DigitKeypadScreen;
