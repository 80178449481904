import AudioButton from '@/features/devices/tablets/components/buttons/AudioButton';
import styles from '@/features/devices/tablets/components/playAudioJob/playAudioJob.module.scss';

const PlayAudio: React.FC = () => {
    return (
        <div className={styles.container}>
            <div>
                <AudioButton />
            </div>
            <div>
                <h3>Appuyez pour écouter ou arrêter l’audio sur la télévision.</h3>
            </div>
        </div>
    );
};

export default PlayAudio;
