// style
import { CheckValidate } from '@/assets/images/icons';
import { Sound4 } from '@/assets/sounds/soundsEffects';
import styles from '@/features/devices/tablets/components/dial/dialCard.module.scss';
import { playSound } from '@/services/global/globalHandleFunctions';
import { motion } from 'framer-motion';

export interface DialCardProps {
    content?: string;
    handleClick?: () => void;
    status?: 'validated' | 'completed';
    disabled?: boolean;
    isSelected?: boolean;
}

const DialCard: React.FC<DialCardProps> = ({
    content,
    handleClick,
    status,
    disabled,
    isSelected,
}) => {
    const isImage = content?.includes('.svg') || content?.includes('.png');

    return (
        <>
            <motion.button
                whileTap={{ scale: 0.9 }}
                className={`${styles.container} ${
                    content === '?' && styles.empty
                }  ${status && styles[status]} ${isSelected && styles.selected} `}
                onClick={() => {
                    handleClick?.();
                    playSound(Sound4);
                }}
                disabled={disabled}
            >
                {status === 'validated' && (
                    <img
                        className={styles.checkBoxIcon}
                        alt='validé'
                        src={CheckValidate}
                    />
                )}
                {status === 'completed' && (
                    <div className={styles.completed}>
                        <p>OK</p>
                    </div>
                )}
                {isImage && (
                    <img
                        src={content}
                        alt='icône'
                        id={status === 'completed' ? styles.completed : ''}
                    />
                )}
                {!isImage && <p>{content}</p>}
            </motion.button>
        </>
    );
};

export default DialCard;
