import createSelectors from '@/store/selectors';
import { deepCopy, INITIAL_GAME_STORE } from '@/sessionInfo/sessionInfo';
import { type GameStatus } from '@/types/global/types';
import { create } from 'zustand';

const useGameStoreBase = create(() => deepCopy(INITIAL_GAME_STORE));

export const useGameStore = createSelectors(useGameStoreBase);

export const setGameStatus = (gameStatus: GameStatus): void =>
    useGameStoreBase.setState({
        gameStatus,
    });

export const setIsAudioPlaying = (isAudioPlaying: boolean): void =>
    useGameStoreBase.setState({
        isAudioPlaying,
    });

export const resetGame = (): void =>
    useGameStoreBase.setState(deepCopy(INITIAL_GAME_STORE));
