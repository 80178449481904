import { ListItemClick } from '@/assets/sounds/soundsEffects';
import globalData from '@/data/global.json';
import Button from '@/features/devices/tablets/components/buttons/Button';
import ClickableItem from '@/features/devices/tablets/components/list/components/ClickableItem';
import styles from '@/features/devices/tablets/components/list/risksOrLeversList.module.scss';
import {
    handleUpdateRiskOrLeverStatus,
    handleValidateGame1RisksAndLevers,
} from '@/services/games/game1/gameOneHandleFunctions';
import {
    getSelectedLevers,
    getSelectedRisks,
} from '@/services/games/game1/gameOneUtils';
import { playSound } from '@/services/global/globalHandleFunctions';
import { totalRisks } from '@/services/global/globalUtils';
import { useGame1Store } from '@/store/games/game1Store';

const RisksOrLeversList: React.FC = () => {
    const { job, risks, levers } = useGame1Store();

    const selectedRisks = getSelectedRisks();
    const numberOfRisksToIdentify = job.risksId?.length;

    const selectedLevers = getSelectedLevers();
    const numberOfLeversToIdentify = job.leversId?.length;

    const handleClick = () => {
        handleValidateGame1RisksAndLevers(job.risksValidated ? 'levers' : 'risks');
    };

    const type = job.risksValidated ? 'levers' : 'risks';

    const itemArray = type === 'levers' ? levers : risks;
    const numberOfItemsSelected = itemArray.filter(
        (item) => item.isSelected
    ).length;

    return (
        <>
            <div className={styles.listContainer}>
                {risks.length !== totalRisks && (
                    <p className={styles.emptyList}>{globalData.tv.risksFound}</p>
                )}
                <>
                    {itemArray.map((item) => {
                        const isDisabled =
                            !item.isSelected &&
                            numberOfItemsSelected === job[`${type}Id`].length;

                        return (
                            <ClickableItem
                                key={item.id}
                                isSelected={item.isSelected}
                                description={item.name}
                                onClick={() => {
                                    handleUpdateRiskOrLeverStatus(
                                        item.id,
                                        type,
                                        !item.isSelected
                                    );
                                    playSound(ListItemClick);
                                }}
                                disabled={isDisabled || risks.length !== totalRisks}
                            />
                        );
                    })}
                </>
            </div>
            {!job.risksValidated ? (
                <>
                    {risks.length === totalRisks && (
                        <Button
                            text={`${globalData.global.validate.toUpperCase()} (${selectedRisks.length}/${numberOfRisksToIdentify})`}
                            color='green'
                            disabled={
                                selectedRisks.length !== numberOfRisksToIdentify
                            }
                            onClick={handleClick}
                        />
                    )}
                </>
            ) : (
                <Button
                    text={`${globalData.global.validate.toUpperCase()} (${selectedLevers.length}/${numberOfLeversToIdentify})`}
                    color='green'
                    disabled={selectedLevers.length < numberOfLeversToIdentify}
                    onClick={handleClick}
                />
            )}
        </>
    );
};

export default RisksOrLeversList;
