import AudioComponent from '@/features/components/AudioComponent';
import styles from '@/features/devices/tablets/components/popup/popup.module.scss';
import { motion } from 'framer-motion';

export interface PopupProps {
    title: string;
    text: string;
    audio: string;
}

const Popup: React.FC<PopupProps> = ({ title, text, audio }) => {
    return (
        <motion.div
            className={styles.popupOverlay}
            initial={{ y: '100%', opacity: 0 }}
            animate={{ y: '0%', opacity: 1 }}
            exit={{ y: '100%', opacity: 0 }}
            transition={{ duration: 0.5 }}
        >
            <AudioComponent src={audio} />
            <div className={styles.popupContent}>
                <h2 className={styles.title}>{title.toUpperCase()}</h2>
                <p className={styles.description}>{text}</p>
            </div>
        </motion.div>
    );
};

export default Popup;
