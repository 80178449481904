import gameFourData from '@/data/gameFourData.json';
import { useGame4Store } from '@/store/games/game4Store';
import {
    DEMANDSTATUS,
    type DemandStatus,
    type PossibleJobGame4Ids,
    type RiskFactor,
} from '@/types/games/game4/types';

export const getRiskFactorById = (id: number): RiskFactor => {
    return gameFourData.riskFactors.find((riskFactor) => riskFactor.id === id)!;
};

export const getNextDemandStatus = (currentStatus: DemandStatus): DemandStatus => {
    const status = Object.values(DEMANDSTATUS);
    const currentIndex = status.indexOf(currentStatus);
    const nextIndex = currentIndex + 1;
    return status[nextIndex]! || status[0];
};

/**
 * Handle the color based on the demand status.
 * @param {DemandStatus} demandStatus - The demand status.
 * @returns {string} The color corresponding to the demand status.
 */
export const handleColor = (demandStatus: DemandStatus): string => {
    switch (demandStatus) {
        case DEMANDSTATUS.NOT_DEMANDING:
            return 'green';
        case DEMANDSTATUS.DEMANDING:
            return 'orange';
        case DEMANDSTATUS.VERY_DEMANDING:
            return 'red';
        default:
            return 'grey';
    }
};

export const isJobDemandingFactorStarted = (
    jobId: PossibleJobGame4Ids
): boolean => {
    const currentAnswers = useGame4Store.getState().currentAnswers;

    return currentAnswers[jobId].some(
        (answer) => answer !== ('Pas sélectionné' as DemandStatus)
    );
};

export const areAllJobDemandingFactorCompleted = (): boolean => {
    const currentAnswers = useGame4Store.getState().currentAnswers;

    return Object.values(currentAnswers).every((answers) =>
        answers.every((answer) => answer !== ('Pas sélectionné' as DemandStatus))
    );
};

export const numberOfCorrectAnswers = (): number => {
    const currentAnswers = useGame4Store.getState().currentAnswers;
    const correctAnswers = gameFourData.goodAnswers;

    // Combine all the answers into a single array
    const currentAnswersArray = Object.values(currentAnswers).reduce(
        (acc, val) => acc.concat(val),
        []
    );
    const correctAnswersArray = Object.values(correctAnswers).reduce(
        (acc, val) => acc.concat(val),
        []
    );
    return currentAnswersArray.filter((answer, index) => {
        return answer === correctAnswersArray[index];
    }).length;
};
