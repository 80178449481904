import { type Game1Store, type JobOmitGame1 } from '@/types/games/game1/types';
import { type Game2Store, type JobOmitGame2 } from '@/types/games/game2/types';
import { type Game3Store, type JobOmitGame3 } from '@/types/games/game3/types';
import {
    type DemandStatus,
    type Game4Store,
    type JobOmitGame4,
    type PossibleJobGame4Ids,
} from '@/types/games/game4/types';
import {
    type DeviceStore,
    type GameStore,
    type OverlayStore,
} from '@/types/games/types';
import {
    type DeviceColorWithTvAndMediator,
    type Nullable,
    type OverlayDescription,
    type OverlayType,
} from '@/types/global/types';
import type { SessionInfo, RoomInfo } from '@/types/sessionInfo/types';
import { type ServerEvents } from '@/types/socket/types';

const MAX_OF_DEVICES_IN_A_ROOM = 6;

export function deepCopy<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj));
}

export function storeSocketInfo(
    session: SessionInfo,
    data: { roomName: string; device: DeviceColorWithTvAndMediator },
    socketId: string
): SessionInfo {
    session[data.roomName].sockets.set(data.device, socketId);

    return session;
}

function getUnfilledRooms(
    connectedSockets: Array<{ id: string; room: string }>,
    storedRoomNames: Array<string>
) {
    const socketsIdsByRooms = connectedSockets.reduce(
        (acc, { id, room }) => {
            acc[room] = acc[room] ?? [];
            acc[room].push(id);
            return acc;
        },
        {} as Record<string, Array<string>>
    );

    //Add all the the empty room
    for (const roomName of storedRoomNames) {
        if (!socketsIdsByRooms[roomName]) {
            socketsIdsByRooms[roomName] = [];
        }
    }

    return Object.entries(socketsIdsByRooms).filter(([, ids]) => {
        return ids.length < MAX_OF_DEVICES_IN_A_ROOM;
    });
}

export function getDisconnectedSocketRoomsWithColor(
    session: SessionInfo,
    connectedSockets: Array<{ id: string; room: string }>
): Nullable<[string, DeviceColorWithTvAndMediator]> {
    const roomsWithMissingSockets = getUnfilledRooms(
        connectedSockets,
        Object.keys(session)
    );

    let missingSocketRoomsWithColor: Nullable<
        [string, DeviceColorWithTvAndMediator]
    > = null;

    for (const [room, ids] of roomsWithMissingSockets) {
        let disconnectedSocketColor = null;
        session[room].sockets.forEach((socketId, color) => {
            if (socketId != null && !ids.includes(socketId)) {
                disconnectedSocketColor = color;
            }
        });

        if (!disconnectedSocketColor) continue;

        missingSocketRoomsWithColor = [room, disconnectedSocketColor];
    }

    return missingSocketRoomsWithColor;
}

export function extractRoomsAndIdsFromSockets(
    sockets: RemoteSocket<ServerEvents, unknown>[]
) {
    return sockets.reduce(
        (output, connectedSocket) => {
            const [id, room] = Array.from(connectedSocket.rooms);
            if (room) {
                output.push({
                    id,
                    room,
                });
            }
            return output;
        },
        [] as Array<{ id: string; room: string }>
    );
}

export function createRoom(roomName: string): SessionInfo {
    return {
        [roomName]: { sockets: new Map(), ...initRoom() },
    };
}

export function deleteEmptyRoom(sessionInfo: SessionInfo) {
    Object.entries(sessionInfo).forEach(([roomName, { sockets }]) => {
        const emptyRoom =
            Array.from(sockets).filter(([, status]) => status !== null).length ===
            0;

        if (emptyRoom) delete sessionInfo[roomName];
    });
}

export function initRoom(): Omit<RoomInfo, 'sockets'> {
    return deepCopy({
        deviceStore: INITIAL_DEVICE_STORE,
        overlayStore: INITAL_OVERLAY_STORE,
        gameStore: INITIAL_GAME_STORE,
        game1Store: INITIAL_GAME1_STORE,
        game2Store: INITIAL_GAME2_STORE,
        game3Store: INITIAL_GAME3_STORE,
        game4Store: INITIAL_GAME4_STORE,
    });
}

export const INITIAL_DEVICE_STORE: DeviceStore = {
    deviceStatus: {
        red: {
            isConnected: false,
            isActivated: false,
            label: 'Tablette rouge',
        },
        blue: {
            isConnected: false,
            isActivated: false,
            label: 'Tablette bleue',
        },
        green: {
            isConnected: false,
            isActivated: false,
            label: 'Tablette verte',
        },
        orange: {
            isConnected: false,
            isActivated: false,
            label: 'Tablette orange',
        },
        tv: {
            isConnected: false,
            isActivated: true,
            label: 'TV',
        },
        mediator: {
            isConnected: false,
            isActivated: false,
            label: 'Tablette Médiateur',
        },
    },
};

export const INITAL_OVERLAY_STORE: OverlayStore = {
    currentOverlayIndex: 0,
    overlayDescriptions: [] as OverlayDescription[],
    overlayType: 'neutral' as OverlayType,
};

export const INITIAL_GAME1_STORE: Game1Store = {
    risks: [],
    levers: [],
    job: {} as JobOmitGame1,
    audioShouldPlay: false,
    risksFound: false,
    leversFound: false,
};

export const INITIAL_GAME2_STORE: Game2Store = {
    jobsGame2: [] as Array<JobOmitGame2>,
    selectedJob: null,
};

export const INITIAL_GAME3_STORE: Game3Store = {
    jobsGame3: [] as JobOmitGame3[],
    selectedJob: null,
    selectedArgument: null,
};

export const INITIAL_GAME4_STORE: Game4Store = {
    jobsGame4: [] as JobOmitGame4[],
    currentAnswers: {} as Record<PossibleJobGame4Ids, DemandStatus[]>,
    selectedJob: null,
};

export const INITIAL_GAME_STORE: GameStore = {
    gameStatus: 'WELCOME_SCREEN',
    isAudioPlaying: false,
};
