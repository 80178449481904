import { PlayButton, StopButton } from '@/assets/images/global';
import styles from '@/features/devices/tablets/components/buttons/audioButton.module.scss';
import { handleUpdateGameOneAudioJobStatus } from '@/services/games/game1/gameOneHandleFunctions';
import { useGame1Store } from '@/store/games/game1Store';
import { useGameStore } from '@/store/gameStore';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';

const AudioButton: React.FC = () => {
    const audioShouldPlay = useGame1Store.use.audioShouldPlay();
    const isAudioPlaying = useGameStore.use.isAudioPlaying();
    const [icon, setIcon] = useState<string>(PlayButton);
    const [userClickedPlay, setUserClickPlay] = useState(audioShouldPlay);

    useEffect(() => {
        setIcon((old) => (old === PlayButton ? StopButton : PlayButton));
    }, [audioShouldPlay]);

    useEffect(() => {
        if (userClickedPlay && !isAudioPlaying) {
            handleUpdateGameOneAudioJobStatus(false);
        }
        // disable exhaustive deps because we don't want to run this effect when isAudioPlaying changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAudioPlaying]);

    const handleButtonClick = () => {
        handleUpdateGameOneAudioJobStatus(!audioShouldPlay);

        setUserClickPlay((previous: any) => !previous);
    };

    return (
        <motion.button
            whileTap={{ scale: 0.9 }}
            className={`${styles.button} ${
                icon === StopButton && styles.isPlaying
            }`}
            onClick={handleButtonClick}
        >
            <motion.img
                src={icon}
                alt='Play/Stop button'
                animate={{ rotate: icon === StopButton ? 90 : 0 }}
                transition={{ duration: 0.25 }}
            />
        </motion.button>
    );
};

export default AudioButton;
