import WaitingTabletFooter from '@/features/devices/tv/components/HUD/WaitingTabletFooter';
import styles from '@/features/devices/tv/components/tvTextBanner/tvTextBanner.module.scss';
import { motion } from 'framer-motion';

interface TvTextBannerProps {
    text?: string;
    currentNumber?: number;
    totalNumber?: number;
    optionnalText?: string;
    tabletName: string;
    isCodeCorrect?: boolean;
    isBeginTextGreenScreen?: boolean;
}

const TvTextBanner: React.FC<TvTextBannerProps> = ({
    text,
    currentNumber,
    totalNumber,
    optionnalText,
    tabletName,
    isCodeCorrect,
    isBeginTextGreenScreen,
}) => {
    const fraction = currentNumber !== undefined && totalNumber !== undefined;

    return (
        <>
            <motion.div
                className={`${styles.container} ${
                    isCodeCorrect && styles.correctCode
                } ${isBeginTextGreenScreen && styles.beginTextScreen}`}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                    duration: 0.2,
                }}
            >
                <motion.div
                    className={
                        isCodeCorrect || isBeginTextGreenScreen
                            ? styles.greenLayout
                            : ''
                    }
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{
                        type: 'spring',
                        stiffness: 100,
                        damping: 20,
                        duration: 0.2,
                    }}
                >
                    <h1>{text}</h1>
                    <div className={styles.line} />
                    <p>{`${fraction ? `${currentNumber}/${totalNumber}` : ''}`}</p>
                    <p>{optionnalText && optionnalText}</p>
                </motion.div>
            </motion.div>
            <WaitingTabletFooter tablet={tabletName} />
        </>
    );
};

export default TvTextBanner;
