import { FullPattern } from '@/assets/images/global';
import AudioComponent from '@/features/components/AudioComponent';
import styles from '@/features/devices/tv/components/confirmOverlay/confirmOverlay.module.scss';
import useCurrentOverlay from '@/hooks/useCurrentOverlay';

const ConfirmOverlay: React.FC = () => {
    const { currentTextAudio, currentTitle, currentText } = useCurrentOverlay();

    return (
        <div className={styles.container}>
            <AudioComponent
                src={currentTextAudio ?? ''}
                trackSource={currentText}
            />
            <div>
                <img src={FullPattern} alt='pattern' />
                <h1>{currentTitle}</h1>
                <div />
                <p>{currentText}</p>
            </div>
        </div>
    );
};

export default ConfirmOverlay;
