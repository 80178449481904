import type { Job, ObjectValues, StrictOmit } from '@/types/global/types.js';

export interface JobGame4 extends Job {
    jobId: number;
    description: string;
}

export type JobOmitGame4 = StrictOmit<JobGame4, 'jobId'>;

export interface RiskFactor {
    id: number;
    name: string;
    icon: string;
}

export const DEMANDSTATUS = {
    NOT_DEMANDING: 'Peu sollicitant',
    DEMANDING: 'Sollicitant',
    VERY_DEMANDING: 'Très sollicitant',
} as const;

export type DemandStatus = ObjectValues<typeof DEMANDSTATUS>;

export type PossibleJobGame4Ids = 1 | 2 | 3 | 4 | 6 | 8 | 14 | 15;

export interface Game4Store {
    jobsGame4: JobOmitGame4[];
    selectedJob: JobOmitGame4 | null;
    currentAnswers: Record<PossibleJobGame4Ids, DemandStatus[]>;
}
