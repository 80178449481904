import MediatorAction from '@/features/devices/tablets/mediator/MediatorAction';
import { useGame3Store } from '@/store/games/game3Store';
import { useOverlayStore } from '@/store/overlayStore';
import {
    handleSelectArgumentGame3,
    handleSelectJobGame3,
    handleValidateGame3,
    handleValidateArgumentGame3,
} from '@/services/games/game3/gameThreeHandleFunctions';
import {
    getArguments,
    getGoodCombinationForJobId,
} from '@/services/games/game3/gameThreeUtils';
import type { PossibleJobGame3Ids } from '@/types/games/game3/types';
import { isObjectEmpty } from '@/services/global/globalUtils';

const GameThreeMediator: React.FC = () => {
    const jobsBelief = useGame3Store.use.jobsGame3();
    const selectedJob = useGame3Store.use.selectedJob();
    const selectedArgument = useGame3Store.use.selectedArgument();
    const game3Arguments = getArguments();

    const { overlayDescriptions } = useOverlayStore();

    return (
        <div>
            {/* Go to next job */}
            <MediatorAction
                title='Passer le message'
                displayingCondition={overlayDescriptions.length > 0}
                handleFunction={handleValidateGame3}
            />

            {/* Validate the selection */}
            <MediatorAction
                title='Valider la sélection'
                displayingCondition={
                    !isObjectEmpty(selectedJob) &&
                    selectedJob !== null &&
                    !isObjectEmpty(selectedArgument) &&
                    selectedArgument !== null &&
                    overlayDescriptions.length === 0
                }
                handleFunction={handleValidateArgumentGame3}
            />

            {/* Good combination for each jobs */}
            {jobsBelief.map((job) => {
                const goodArgumentIdForCurrentJob = getGoodCombinationForJobId(
                    job.id as PossibleJobGame3Ids
                );
                if (goodArgumentIdForCurrentJob === -1) return;

                return (
                    <MediatorAction
                        key={job.id}
                        title={`Afficher la bonne combinaison pour: ${job.firstName}`}
                        displayingCondition={
                            !job.isValidated && overlayDescriptions.length === 0
                        }
                        handleFunction={() => {
                            handleSelectJobGame3(job);
                            handleSelectArgumentGame3(
                                game3Arguments[goodArgumentIdForCurrentJob - 1]
                            );
                        }}
                        type='secondary'
                    />
                );
            })}
        </div>
    );
};

export default GameThreeMediator;
