import type React from 'react';
import styles from '@/features/devices/tv/components/jobs/displayJobs.module.scss';
// utils
import { Max } from '@/assets/images/characters';
import AudioComponent from '@/features/components/AudioComponent';
import TextBox from '@/features/devices/tv/components/textBox/TextBox';
import { useGame1Store } from '@/store/games/game1Store';

interface DisplayJobsProps {
    color?: string;
    title?: string;
    text?: string;
    isIntroduction?: boolean;
    audio?: string;
}

const DisplayJobs: React.FC<DisplayJobsProps> = ({
    color = '',
    title,
    text,
    isIntroduction,
    audio,
}) => {
    const job = useGame1Store.use.job();

    return (
        <div className={`${styles[`${color}Container`]}`}>
            <div className={styles.jobImg}>
                <img src={isIntroduction ? Max : job?.fullIcon} alt={job?.name} />
            </div>
            <AudioComponent
                src={audio ?? job.audioPath ?? ''}
                trackSource={job?.description}
            />
            <TextBox
                title={title ?? job?.name}
                text={text ?? job?.description}
                firstName={isIntroduction ? 'Max' : job?.firstName}
            />
        </div>
    );
};

export default DisplayJobs;
