import { type Argument, type PossibleJobGame3Ids } from '@/types/games/game3/types';
import dataGameThree from '@/data/gameThreeData.json';

export const getArguments = (): Argument[] => {
    return dataGameThree.arguments;
};

export const isGoodCombination = (
    jobId: PossibleJobGame3Ids,
    argumentId: number
): boolean => {
    const goodJobsWithArgumentsIds = dataGameThree.goodCombinations;
    return !!goodJobsWithArgumentsIds.find(
        (goodJobWithArgument) =>
            goodJobWithArgument.jobId === jobId &&
            goodJobWithArgument.argumentId === argumentId
    );
};

export const getGoodCombinationForJobId = (jobId: PossibleJobGame3Ids): number => {
    const goodJobWithArgument = dataGameThree.goodCombinations.find(
        (goodJobWithArgument) => goodJobWithArgument.jobId === jobId
    );
    return goodJobWithArgument ? goodJobWithArgument.argumentId : -1;
};
