import { MaxFull } from '@/assets/images/characters/full';
import AudioComponent from '@/features/components/AudioComponent';
import TextAnimation from '@/features/components/text/TextAnimation';
import styles from '@/features/devices/tv/components/prevention/prevention.module.scss';
import useCurrentOverlay from '@/hooks/useCurrentOverlay';

const Prevention: React.FC = () => {
    const { currentTextAudio, currentTitle, currentText } = useCurrentOverlay();

    return (
        <div className={styles.preventionContainer}>
            <AudioComponent src={currentTextAudio} trackSource={currentText} />
            <img src={MaxFull} alt='Max' />
            <div>
                <div className={styles.textContainer}>
                    <h1>{currentTitle}</h1>
                    <TextAnimation text={currentText} />
                </div>
            </div>
        </div>
    );
};

export default Prevention;
