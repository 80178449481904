import DemandingFactorRecap from '@/features/devices/tv/components/demandingFactors/DemandingFactorRecap';
import styles from '@/features/devices/tv/components/demandingFactors/jobDemandingFactorRecap.module.scss';
import gameFourData from '@/data/gameFourData.json';
import { useGame4Store } from '@/store/games/game4Store';
import { handleColor } from '@/services/games/game4/gameFourUtils';
import {
    type DemandStatus,
    type JobOmitGame4,
    type PossibleJobGame4Ids,
} from '@/types/games/game4/types';

interface JobDemandingFactorRecapProps {
    job: JobOmitGame4;
    isRecapScreen?: boolean;
}

const risksFactors = gameFourData.riskFactors;
const goodAnswers = gameFourData.goodAnswers;

const JobDemandingFactorRecap: React.FC<JobDemandingFactorRecapProps> = ({
    job: { icon, name, id },
    isRecapScreen,
}) => {
    const currentAnswers = useGame4Store.use.currentAnswers();

    const renderRiskFactors = () =>
        risksFactors.map((riskFactor, index) => {
            const currentStatus = isRecapScreen
                ? goodAnswers[id as PossibleJobGame4Ids][index]
                : currentAnswers[id as PossibleJobGame4Ids][index];

            const color = handleColor(currentStatus as DemandStatus);

            if (currentStatus === ('Pas sélectionné' as DemandStatus)) return null;

            return (
                <DemandingFactorRecap
                    key={riskFactor.id}
                    color={color}
                    content={isRecapScreen ? undefined : riskFactor.name}
                    iconPath={riskFactor.icon}
                />
            );
        });

    return (
        <div className={isRecapScreen ? styles.containerRecap : styles.container}>
            <img src={icon} alt='Head of the job' />
            {isRecapScreen && <p>{name}</p>}
            <div>{renderRiskFactors()}</div>
        </div>
    );
};

export default JobDemandingFactorRecap;
