import { motion } from 'framer-motion';
import { PadClick } from '@/assets/sounds/soundsEffects';
import styles from '@/features/devices/tablets/components/keypad/digitKeypad.module.scss';
import { playSound } from '@/services/global/globalHandleFunctions';

const KEYPAD_SIZE = 10;

interface DigitKeypadProps {
    setCode: React.Dispatch<React.SetStateAction<string>>;
}

const DigitKeypad: React.FC<DigitKeypadProps> = ({ setCode }) => {
    return (
        <div className={styles.padcontainer}>
            {Array.from(Array(KEYPAD_SIZE).keys()).map((number) => {
                let style = '';
                switch (number) {
                    case 0:
                        return;
                    case 1:
                        style = 'topLeftRounded';
                        break;
                    case 3:
                        style = 'topRightRounded';
                        break;
                    case 7:
                        style = 'bottomLeftRounded';
                        break;
                    case 9:
                        style = 'bottomRightRounded';
                        break;
                    default:
                        break;
                }

                return (
                    <motion.button
                        key={number}
                        className={styles[style]}
                        whileTap={{ scale: 0.9 }}
                        onClick={() => {
                            playSound(PadClick);
                            setCode((prev) => prev + number);
                        }}
                    >
                        <span>{number}</span>
                    </motion.button>
                );
            })}
            <motion.button
                className={styles.bottomRounded}
                whileTap={{ scale: 0.9 }}
                onClick={() => {
                    playSound(PadClick);
                    setCode((prev) => prev + '0');
                }}
            >
                <span>0</span>
            </motion.button>
        </div>
    );
};

export default DigitKeypad;
