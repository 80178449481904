import AudioComponent from '@/features/components/AudioComponent';
import JobDemandingFactorRecap from '@/features/devices/tv/components/demandingFactors/JobDemandingFactorRecap';
import WaitingTabletFooter from '@/features/devices/tv/components/HUD/WaitingTabletFooter';
import styles from '@/features/devices/tv/components/recapJobsRepartition/recapJobRepartition.module.scss';
import { useGame4Store } from '@/store/games/game4Store';
import { useGameStore } from '@/store/gameStore';
import {
    getExposedJobs,
    getNumberOfWellRepartedJobsGame2,
} from '@/services/games/game2/gameTwoUtils';
import { type JobOmitGame2 } from '@/types/games/game2/types';
import { numberOfCorrectAnswers } from '@/services/games/game4/gameFourUtils';
import { type JobOmitGame4 } from '@/types/games/game4/types';
import { type OverlayType } from '@/types/global/types';
import { useMemo } from 'react';

interface RecapJobRepartitionProps {
    title: string;
    text: string;
    scoreStatus: OverlayType;
    audio: string;
}

const RecapJobRepartition: React.FC<RecapJobRepartitionProps> = ({
    title,
    text,
    scoreStatus,
    audio,
}) => {
    const gameStatus = useGameStore.use.gameStatus();
    const isGame2 = useMemo(() => gameStatus === 'GAME2ACTION', [gameStatus]);

    const wellRepartedJobCountGame2 = getNumberOfWellRepartedJobsGame2();

    const wellRepartedJobCountGame4 = numberOfCorrectAnswers();

    const computeJobs = (): JobOmitGame4[] | JobOmitGame2[] => {
        if (isGame2) return getExposedJobs();
        return useGame4Store.use.jobsGame4();
    };

    return (
        <div className={styles[`${scoreStatus}Wrapper`]}>
            <div className={styles[`${scoreStatus}Container`]}>
                <div className={styles.textContainer}>
                    <AudioComponent src={audio} />
                    <p>
                        {title.replace(
                            '[NOMBRE]',
                            (isGame2
                                ? wellRepartedJobCountGame2
                                : wellRepartedJobCountGame4
                            ).toString()
                        )}
                    </p>
                    <p>{text}</p>
                </div>
                <div className={styles.jobsContainer}>
                    {computeJobs().map((job) => {
                        return isGame2 ? (
                            <div key={job.id}>
                                <img src={job.icon} alt='icône' />
                                <p>{job.name}</p>
                            </div>
                        ) : (
                            <JobDemandingFactorRecap
                                key={job.id}
                                job={job}
                                isRecapScreen
                            />
                        );
                    })}
                </div>
                <WaitingTabletFooter tablet='bleue' />
            </div>
        </div>
    );
};

export default RecapJobRepartition;
