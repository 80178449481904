import { Trickyberpunk } from '@/assets/images/global';
import { Sound1 } from '@/assets/sounds/soundsEffects';
import globalData from '@/data/global.json';
import Footer from '@/features/devices/tablets/components/HUD/Footer';
import Header from '@/features/devices/tablets/components/HUD/Header';
import FullScreenButton from '@/features/devices/tablets/components/fullScreenButton/FullScreenButton';
import DigitKeypadScreen from '@/features/devices/tablets/components/keypad/DigitKeypadScreen';
import ArgumentList from '@/features/devices/tablets/components/list/ArgumentList';
import tabletStyle from '@/features/devices/tablets/components/screens/tabletScreen.module.scss';
import useReSynchronizeDevice from '@/hooks/useReSynchronizeDevice';
import { handleUpdateJobGame2 } from '@/services/games/game2/gameTwoHandleFunctions';
import { playSound } from '@/services/global/globalHandleFunctions';
import { emitSocketEvent } from '@/services/global/globalUtils';
import { useDeviceStore } from '@/store/deviceStore';
import { useGameStore } from '@/store/gameStore';
import { useGame2Store } from '@/store/games/game2Store';
import { useEffect } from 'react';
import { ExposureStatus } from '@/types/games/game2/types';
import { GAMESTATUS } from '@/types/global/types';

const RedActionSwitch: React.FC = () => {
    const gameStatus = useGameStore.use.gameStatus();
    const { red } = useDeviceStore.use.deviceStatus();
    const selectedJob = useGame2Store.use.selectedJob();
    const isTabletInactive = !red.isActivated;

    useReSynchronizeDevice('red');

    useEffect(() => {
        document.title = `🔴 ${red.label}`;
        emitSocketEvent('send_synchronize_device', {
            deviceColor: 'red',
        });
    }, [red.label]);

    const handleRender = () => {
        switch (gameStatus) {
            case GAMESTATUS.GAME1ACTION:
                return <DigitKeypadScreen />;
            case GAMESTATUS.GAME1PREVENTION:
            case GAMESTATUS.GAME2ACTION:
                return (
                    <FullScreenButton
                        onClick={() => {
                            handleUpdateJobGame2(
                                selectedJob!,
                                ExposureStatus.EXPOSED
                            );
                            playSound(Sound1);
                        }}
                        gradientColor='red'
                        subtext={globalData.tablet.jobRepartitionClick}
                        colorOfSubtext='red'
                        text={globalData.tablet.surexposed}
                    />
                );
            case GAMESTATUS.GAME3ACTION:
                return <ArgumentList />;
            case GAMESTATUS.GAME4ACTION:
            case GAMESTATUS.FINISHED:
                break;
        }
    };

    return (
        <div className={tabletStyle.redTabletContainer}>
            {isTabletInactive ? (
                <>
                    <Header />
                    <img
                        src={Trickyberpunk}
                        alt='Logo tricky'
                        className={tabletStyle.trickyberpunk}
                    />
                    <Footer />
                </>
            ) : (
                <>{handleRender()}</>
            )}
        </div>
    );
};

export default RedActionSwitch;
