import { Trickyberpunk } from '@/assets/images/global';
import Footer from '@/features/devices/tablets/components/HUD/Footer';
import Header from '@/features/devices/tablets/components/HUD/Header';
import HeadEmployesKeyboard from '@/features/devices/tablets/components/headEmployesKeyboard/HeadEmployesKeyboard';
import RisksOrLeversList from '@/features/devices/tablets/components/list/RisksOrLeversList';
import tabletStyle from '@/features/devices/tablets/components/screens/tabletScreen.module.scss';
import useReSynchronizeDevice from '@/hooks/useReSynchronizeDevice';
import { emitSocketEvent } from '@/services/global/globalUtils';
import { useDeviceStore } from '@/store/deviceStore';
import { useGameStore } from '@/store/gameStore';
import { useEffect } from 'react';
import { GAMESTATUS } from '@/types/global/types';

const GreenActionSwitch: React.FC = () => {
    const gameStatus = useGameStore.use.gameStatus();
    const { green } = useDeviceStore.use.deviceStatus();
    const isTabletInactive = !green.isActivated;

    useReSynchronizeDevice('green');

    useEffect(() => {
        document.title = `🟢 ${green.label}`;
        emitSocketEvent('send_synchronize_device', {
            deviceColor: 'green',
        });
    }, [green.label]);

    const handleRender = () => {
        switch (gameStatus) {
            case GAMESTATUS.GAME1ACTION:
                return <RisksOrLeversList />;
            case GAMESTATUS.GAME2ACTION:
            case GAMESTATUS.GAME3ACTION:
            case GAMESTATUS.GAME4ACTION:
                return <HeadEmployesKeyboard />;
            case GAMESTATUS.WELCOME_SCREEN:
            case GAMESTATUS.INTRODUCTION:
            case GAMESTATUS.GAME1PREVENTION:
            case GAMESTATUS.FINISHED:
                break;
        }
    };

    return (
        <div className={tabletStyle.greenTabletContainer}>
            {isTabletInactive ? (
                <>
                    <Header />
                    <img
                        src={Trickyberpunk}
                        alt='Logo tricky'
                        className={tabletStyle.trickyberpunk}
                    />
                    <Footer />
                </>
            ) : (
                <>{handleRender()}</>
            )}
        </div>
    );
};

export default GreenActionSwitch;
