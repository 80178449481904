import Exposed from '@/features/components/gameTwo/Exposed';
import RecapJobRepartition from '@/features/devices/tv/components/recapJobsRepartition/RecapJobRepartition';
import useCurrentOverlay from '@/hooks/useCurrentOverlay';
import { OverlayType } from '@/types/global/types';

const GameTwo: React.FC = () => {
    const { currentTitle, currentText, overlayType } = useCurrentOverlay();

    const handleAudio = () => {
        switch (overlayType) {
            case OverlayType.ERROR:
                return 'error';
            case OverlayType.SUCCESS:
                return 'success';
            default:
                return 'success';
        }
    };

    return (
        <>
            {currentText ? (
                <RecapJobRepartition
                    audio={handleAudio()}
                    title={currentTitle}
                    text={currentText}
                    scoreStatus={overlayType}
                />
            ) : (
                <Exposed />
            )}
        </>
    );
};

export default GameTwo;
