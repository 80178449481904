import type { Job, StrictOmit } from '@/types/global/types.js';

export interface JobGame2 extends Job {
    jobId: number;
    description: string;
    audioPath: string;
    status: keyof typeof ExposureStatus;
}

export type JobOmitGame2 = StrictOmit<JobGame2, 'jobId'>;

export const ExposureStatus = {
    EXPOSED: 'EXPOSED',
    NOT_EXPOSED: 'NOT_EXPOSED',
    NOT_SELECTED: 'NOT_SELECTED',
} as const;

export interface Game2Store {
    jobsGame2: JobOmitGame2[];
    selectedJob: JobOmitGame2 | null;
}
