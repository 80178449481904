import styles from '@/features/devices/tv/components/textBox/textBox.module.scss';
import { motion } from 'framer-motion';

interface TextBoxProps {
    title?: string;
    text?: string;
    firstName?: string;
}

const TextBox: React.FC<TextBoxProps> = ({ title, text, firstName }) => {
    const slideDownVariants = {
        hidden: { opacity: 0, y: '100%' },
        visible: { opacity: 1, y: '0%' },
    };

    return (
        <motion.div
            className={styles.textBox}
            initial='hidden'
            animate='visible'
            variants={slideDownVariants}
            transition={{ duration: 0.9 }}
        >
            <div>
                <div>
                    <h3>{`${firstName} - ${title}`}</h3>
                    <p>{text}</p>
                </div>
            </div>
        </motion.div>
    );
};

export default TextBox;
