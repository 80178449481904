import { useOverlayStore } from '@/store/overlayStore';
import { type OverlayType } from '@/types/global/types';

export default function useCurrentOverlay(): {
    currentTextAudio: string;
    currentTitle: string;
    currentText: string;
    overlayType: OverlayType;
} {
    const {
        overlayDescriptions: currentOverlayDescriptions,
        currentOverlayIndex,
        overlayType,
    } = useOverlayStore();

    const currentTextAudio = currentOverlayDescriptions[currentOverlayIndex]?.audio;
    const currentTitle = currentOverlayDescriptions[currentOverlayIndex]?.title;
    const currentText = currentOverlayDescriptions[currentOverlayIndex]?.text;

    return { currentTextAudio, currentTitle, currentText, overlayType };
}
