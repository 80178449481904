import SocketService from '@/services/SocketServices';
import { setDeviceConnectedStatus } from '@/store/deviceStore';
import { useEffect } from 'react';
import type { DeviceColorWithTvAndMediator } from '@/types/global/types';

export default function useReconnection(): void {
    const socket = SocketService.getInstance().getSocket();

    useEffect(() => {
        socket.on('receive_device_connected_status', (data: { deviceColor: string; status: boolean; }) => {
            setDeviceConnectedStatus(
                data.deviceColor as DeviceColorWithTvAndMediator,
                data.status
            );
        });

        return () => {
            socket.off('receive_device_connected_status');
        };
    }, []);
}
