import Button from '@/features/devices/tablets/components/buttons/Button';
import DialCard from '@/features/devices/tablets/components/dial/DialCard';
import styles from '@/features/devices/tablets/components/headEmployesKeyboard/headEmployesKeyboard.module.scss';
import globalData from '@/data/global.json';
import { useGame2Store } from '@/store/games/game2Store';
import { useGame3Store } from '@/store/games/game3Store';
import { useGame4Store } from '@/store/games/game4Store';
import { useGameStore } from '@/store/gameStore';
import {
    handleSelectGame2Job,
    handleValidateGame2,
} from '@/services/games/game2/gameTwoHandleFunctions';
import { ExposureStatus } from '@/types/games/game2/types';
import { handleSelectJobGame3 } from '@/services/games/game3/gameThreeHandleFunctions';
import {
    handleSelectGame4Job,
    handleValidateGame4,
} from '@/services/games/game4/gameFourHandleFunctions';
import { areAllJobDemandingFactorCompleted } from '@/services/games/game4/gameFourUtils';
import {
    type DemandStatus,
    type PossibleJobGame4Ids,
} from '@/types/games/game4/types';
import { GAMESTATUS } from '@/types/global/types';

const HeadEmployesKeyboard: React.FC = () => {
    const gameStatus = useGameStore.use.gameStatus();

    // Game 2
    const jobsGame2 = useGame2Store.use.jobsGame2();
    const currentJobExposure = useGame2Store.use.selectedJob();

    // Game 3
    const jobsGame3 = useGame3Store.use.jobsGame3();
    const currentJobBelief = useGame3Store.use.selectedJob();

    // Game 4
    const jobsGame4 = useGame4Store.use.jobsGame4();
    const currentJobDemandingFactor = useGame4Store.use.selectedJob();
    const currentAnswers = useGame4Store.use.currentAnswers();

    const handleRender = () => {
        if (gameStatus === GAMESTATUS.GAME2ACTION) {
            const areAllJobsExposureSet = jobsGame2.every(
                (job) => job.status !== ExposureStatus.NOT_SELECTED
            );
            return (
                <>
                    {jobsGame2.map((job) => (
                        <DialCard
                            key={job.id}
                            content={job.icon}
                            handleClick={() => handleSelectGame2Job(job)}
                            status={
                                job.status !== ExposureStatus.NOT_SELECTED
                                    ? 'validated'
                                    : undefined
                            }
                            isSelected={job.id === currentJobExposure?.id}
                        />
                    ))}
                    <Button
                        color='green'
                        onClick={handleValidateGame2}
                        text='Valider'
                        disabled={!areAllJobsExposureSet}
                    />
                </>
            );
        }
        if (gameStatus === GAMESTATUS.GAME3ACTION) {
            return (
                <>
                    {jobsGame3.map((job) => (
                        <DialCard
                            key={job.id}
                            content={job.icon}
                            status={job.isValidated ? 'completed' : undefined}
                            handleClick={() => handleSelectJobGame3(job)}
                            isSelected={job.id === currentJobBelief?.id}
                            disabled={job.isValidated}
                        />
                    ))}
                </>
            );
        }
        if (gameStatus === GAMESTATUS.GAME4ACTION) {
            return (
                <>
                    {jobsGame4.map((job) => {
                        const cardStatus = currentAnswers[
                            job.id as PossibleJobGame4Ids
                        ].every(
                            (demandStatus) =>
                                demandStatus !== ('Pas sélectionné' as DemandStatus)
                        )
                            ? 'completed'
                            : undefined;
                        return (
                            <DialCard
                                key={job.id}
                                content={job.icon}
                                status={cardStatus}
                                handleClick={() => handleSelectGame4Job(job)}
                                isSelected={
                                    job.id === currentJobDemandingFactor?.id
                                }
                            />
                        );
                    })}
                    <Button
                        color='green'
                        onClick={handleValidateGame4}
                        text={globalData.global.validate}
                        disabled={!areAllJobDemandingFactorCompleted()}
                        additionalStyle={{
                            position: 'absolute',
                            bottom: '15%',
                        }}
                    />
                </>
            );
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles['heads-container']}>{handleRender()}</div>
        </div>
    );
};

export default HeadEmployesKeyboard;
